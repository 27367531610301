@import "palettes";
@import "theme";
@import "mixins";

.post_excerpt {
  margin: 20px 0;
  @include trim-v-margins;

  & > *:first-child {
    font-weight: $semi-bold;
  }

  & > div {
    text-align: left;
  }
}
